import { Spin, Button, message, Progress, Upload, Drawer, Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "./Footer";

const Home = () => {
  const [field, setField] = useState();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [imageResult, setImageResult] = useState(null);
  const [drawer, setDrawer] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [image, setImage] = useState([]);
  const [time, setTime] = useState("");
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      const timeString = `${hours}:${minutes}:${seconds}`;
      setTime(timeString);
    }, 1000);
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);
  const getRequest = () => {
    if (!field) {
      message.open({
        type: "error",
        content: "Write something in input!",
        className: "mt-20",
      });
    } else {
      setLoading(true);
      axios
        .post("https://chatgpt1-huqe.onrender.com/api/third-party-endpoint/detectText", {
          input_text: field,
        })
        .then((res) => {
          if (res.data.success) {
            console.log(res.data.data);
            setResult(res.data);
            setOpenModal(true);
            message.open({
              type: "success",
              content: "Content Detected!",
              className: "mt-20",
            });
          }
        })
        .catch((err) =>
          message.open({
            type: "error",
            content: "Something went wrong!",
            className: "mt-20",
          })
        )
        .finally(() => setLoading(false));
    }
  };
  const handleCustomReq = async ({ file, onSuccess, onError }) => {
    try {
      // Read the file to get a data URL
      const dataUrl = await readFileAsDataUrl(file);
      const base64Data = await readFileAsBase64(file);

      const newFile = {
        ...file,
        name: file.name,
        thumbUrl: dataUrl, // Use the data URL as the thumbUrl
      };
      setImage([newFile]);
      setPageLoading(true);
      axios
        .post("https://chatgpt1-huqe.onrender.com/api/third-party-endpoint/detectImage", {
          base64_image: base64Data,
        })
        .then((res) => {
          if (res.data.error_code === 0) {
            setImageResult(res.data.predictions);
            setDrawer(true);
          } else {
            message.open({
              type: "error",
              content: res.data.message,
              className: "mt-20",
            });
            setImage([]);
          }
        })
        .catch((err) =>
          message.open({
            type: "error",
            content: "Unable to Detect Image!",
            className: "mt-20",
          })
        )
        .finally(() => setPageLoading(false));
      // Update the state or perform any other necessary actions

      // Call onSuccess to indicate a successful upload
      onSuccess();
    } catch (error) {
      // Handle errors
      console.error(error);

      // Call onError to indicate an error during upload
      onError(error);
    }
  };
  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64Data = reader.result.split(",")[1]; // Remove the data URI prefix
        resolve(base64Data);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };
  // Helper function to read a file as a data URL
  const readFileAsDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  return (
    <Spin className="flex justify-center items-center" spinning={pageLoading}>
      <div className="grid grid-cols-1 h-screen md:grid-cols-2 pt-64 mb-64 md:mb-20 md:pt-0 place-items-center place-content-center">
        <div className="text-start w-9/12">
          <p className="font-bold text-xl md:text-4xl">
            AI Detector for ChatGPT, GPT4, Bard & AI Image Detector
          </p>
          <p className="mt-5 font-semibold text-md md:text-xl">
            Use our AI Detector (now with 98% accuracy) to see if your text is
            human or AI generated from ChatGPT, GPT4, & Bard. Our AI checker is
            one of the most trusted and goes deeper than generic AI content
            detectors. If your writing is AI detected, it may negatively affect
            how search engines rank content, professors grade essays, and even
            readers' opinion.
          </p>
          <p className="mt-2 text-2xl italic font-bold">
            Image Detect Feature!
          </p>
          <Upload
            customRequest={handleCustomReq}
            listType="picture"
            accept=".jpg, .jpeg, .png"
            defaultFileList={[]}
            fileList={image}
            maxCount={1}
          >
            <Button
              className="bg-black h-10 border-none mt-4"
              style={{ color: "white" }}
              // icon={<UploadOutlined />}
            >
              Upload (Max: 1)
            </Button>
            <p className="text-xs text-red-500">
              Image size should be less then or equal to 80kb
            </p>
          </Upload>
        </div>
        <div className="flex flex-col m-4 justify-center w-full md:w-full   items-center  mx-3 md:mx-0 ">
          <p className="text-start text-xl mt-8 text-red-500 mb-2">
            Paste your text to get result!
          </p>
          <textarea
            cols={12}
            rows={10}
            value={field}
            onChange={(e) => setField(e.target.value)}
            className="border border-black w-11/12 md:w-9/12 rounded-md p-4"
            placeholder="Enter Some Text"
          />
          <div className="flex flex-wrap justify-around gap-10 mt-4 items-center float-right">
            <Button
              loading={loading}
              onClick={() => getRequest()}
              style={{ color: "white" }}
              className="bg-black h-10 border-none text-white"
            >
              Check Content
            </Button>
            <Button
              onClick={() => {
                setResult(null);
                setField("");
              }}
              style={{ color: "white" }}
              className="bg-black h-10 border-none text-white"
            >
              Clear
            </Button>
            <div className="font-bold text-center bg-black text-white p-2 rounded-md">
              <p className="">{new Date().toDateString()}</p>
              <p>{time}</p>
            </div>
          </div>

          {/* {result ? (
            <>
              <Progress
                type="circle"
                className="mt-8"
                strokeColor={`${
                  result.data.is_gpt_generated <= 25
                    ? "red"
                    : result.data.is_gpt_generated <= 50
                    ? "yellow"
                    : "green"
                }`}
                percent={result.data.is_gpt_generated}
              />
              <p className="font-bold text-xl">
                {result.data.is_gpt_generated === 100 && "100%"} Look like
                written by AI!
              </p>
              <p className="font-medium mt-4">
                Total No.Of Words: {result.data.words_count}
              </p>
            </>
          ) : null} */}
        </div>
      </div>

      {/* {result && result.data.gpt_generated_sentences.length > 0 && (
        <>
          <p className="p-4 font-bold text-2xl mt-32 md:mt-4">
            Ai Generated Sentences:
          </p>
          <div className="p-4 max-h-[500px]  m-4 shadow-md shadow-gray-700 overflow-y-scroll">
            {result && result.data.gpt_generated_sentences.length > 0
              ? result.data.gpt_generated_sentences.map((i, index) => {
                  return (
                    <p className="mt-3 font-medium shadow-md shadow-gray-400 p-3">
                      {index}. {i}
                    </p>
                  );
                })
              : ""}
          </div>
        </>
      )} */}
      <Footer />
      {drawer && (
        <Drawer
          title="Image Details"
          className="z-[999] mt-16 "
          placement="right"
          headerStyle={{ background: "black", color: "white" }}
          onClose={() => setDrawer(false)}
          open={drawer}
        >
          {Object.keys(imageResult).map((i, index) => {
            return (
              <div className="flex justify-between mb-5 items-center">
                <p className="capitalize font-bold">{i}</p>
                <p className="font-semibold">{imageResult[i]}</p>
              </div>
            );
          })}
        </Drawer>
      )}
      {openModal && (
        <Modal
        footer={null}
        width={2000}
        title={"Result"}
        onCancel={() => setOpenModal(false)}
        open={openModal}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 place-items-center">
            <div className="flex flex-col justify-center items-center">
              <Progress
                type="circle"
                className="mt-8"
                strokeColor={`${
                  result.data.is_gpt_generated <= 25
                    ? "red"
                    : result.data.is_gpt_generated <= 50
                    ? "yellow"
                    : "green"
                }`}
                percent={result.data.is_gpt_generated}
              />
              <p className="font-bold text-xl">
                {result.data.is_gpt_generated === 100 && "100%"} Look like
                written by AI!
              </p>
              <p className="font-medium mt-4">
                Total No.Of Words: {result.data.words_count}
              </p>
            </div>
            <div>
            <p className="p-4 font-bold text-2xl mt-6 md:mt-4">
            Ai Generated Sentences:
          </p>
          <div className="p-4 max-h-[500px]  m-4 shadow-md shadow-gray-700 overflow-y-scroll">
            {result && result.data.gpt_generated_sentences.length > 0
              ? result.data.gpt_generated_sentences.map((i, index) => {
                  return (
                    <p className="mt-3 font-medium shadow-md shadow-gray-400 p-3">
                      {index}. {i}
                    </p>
                  );
                })
              : ""}
          </div>
            </div>
          </div>
        </Modal>
      )}
    </Spin>
  );
};

export default Home;
