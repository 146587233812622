import { Button, Modal, message } from "antd";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const [openModal, setOpenModal] = useState(false);
  const [text, setText] = useState("");

  return (
    <>
      <footer className=" bg-black w-full  p-4">
        <div className=" bg-white text-center w-full p-2">
          <p>(Disclaimer: This website is in no way affiliated with Gpt-Zero.com ,Gpt-Zero.org , Gpt-Zero.cc ,  ZeroGpt.com , GptZero.cc , GptZero.dev , 
GptZero.Me, GptZero.tools , Zerogpt.cc etc )</p>
        </div>
        <div className="flex cursor-pointer justify-start md:justify-around gap-6 items-center flex-wrap text-white font-bold text-sm">
          <div>
            <p>Puneet Agarwal</p>
            <p>Email I'd: puneet@gptzero.com, info@gptzero.com</p>
          </div>
          <div>
            <p onClick={() => setOpenModal(true)}>Our Policy</p>
            <NavLink to={"/termsOfUse"}>
              {" "}
              <p style={{ color: "white" }}>Terms of use</p>
            </NavLink>
          </div>
          <div>
            <p>Contact Us</p>
            <p>+919602473676</p>
            <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              rows={12}
              className="h-20 rounded-md border border-blue-400 focus:outline-none text-black p-3 mt-2"
              placeholder="Enter Message"
            />
            <br />
            <Button
              onClick={() => {
                if (!text) {
                  message.open({
                    type: "error",
                    content: "Write something!",
                    className: "mt-20",
                  });
                } else {
                  message.open({
                    type: "success",
                    content: "Message submitter successfully!",
                    className: "mt-20",
                  });
                  setText("");
                }
              }}
              style={{ color: "white" }}
              className="w-full bg-blue-400 text-black border-none"
            >
              Submit
            </Button>
          </div>
        </div>
      </footer>
      {openModal && (
        <Modal
          footer={null}
          className="h-32"
          width={2000}
          title={"Privacy & Policy"}
          onCancel={() => setOpenModal(false)}
          open={openModal}
        >
          <div className=" overflow-y-scroll">
            <p className="leading-8">
              This website is maintained by{" "}
              <a href="GPTZero.com">GPTZero.com</a> (“we” or “GPT”). You can
              contact us directly either by emailing a.puneet07@gmail.com or
              give us a call at +91-9602473676. We collect any information
              available from your use of the site including your IP Address,
              where you go on the website and how long you stay on a particular
              page. All of this information is collected via Google Analytics
              and none of it is personalized. If information is not gathered by
              Google then we do not collect it, unless you are to fill out a
              form on our website, in which case we also collect your submitted
              Name, Email, Phone Number, and Message. We do not drop cookies,
              have any other logs or track you in any way. This information is
              used ONLY for us to contact you regarding your inquiry as well as
              statistical purposes pertaining to use of the site. Your
              information is securely stored on our servers which is behind a
              firewall. We will not sell your information under any
              circumstances. We will only share your information as we are
              required to do so by law. Information from prospects is kept for
              two years and then automatically deleted. You as a client have the
              right to request your files. Upon your request we will provide
              them within a reasonable period of time in a digital downloadable
              format. Prospects have the right under India’s The Digital
              Personal Data Protection Act to ask us to delete their personally
              identifiable information. We will only have such information if
              you filled out a form.
            </p>
            <p className="leading-8">
              {" "}
              GPTZero.com uses reasonable physical, technical, and
              administrative measures to safeguard information in its possession
              against loss, theft and unauthorized use, disclosure, or
              modification. We may revise this Privacy Policy without notice to
              you. You should review this Privacy Policy, as well as the Terms
              of Use and Legal Notice, from time to time because these terms are
              binding on you, and your continued use of this Website constitutes
              your unconditional acceptance of them. By continuing to use the
              Website after any changes have been made to the Terms of Use,
              Legal Notice and/or Privacy Policy, you indicate your consent to
              those changes.
            </p>
            <br />
          </div>
          <div
            onClick={() => setOpenModal(false)}
            className="flex mt-5 justify-end items-center"
          >
            <p className=" bg-black p-3 text-white rounded-md font-semibold hover:bg-blue-400 cursor-pointer">
              Go Back
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Footer;
