import React from 'react'
import { NavLink } from 'react-router-dom'

const Navbar = () => {
  return (
    <div className="bg-black z-[9999]  fixed w-full p-4 tetx-white flex justify-between items-center flex-wrap gap-y-4 text-white font-bold">
    <div>
    <p className=" text-lg rounded-md  hover:bg-blue-300">
      Gptzero
    </p>
    <p className="mt-2 text-xs rounded-md  hover:bg-blue-300">
       A 2Microns Company
    </p>
    </div>
    <NavLink to="/">
    <p
      className="cursor-pointer text-xs shadow shadow-gray-500 p-3 rounded-md hover:bg-blue-300"
    >
      Home
    </p>
    </NavLink>
  </div>
  )
}

export default Navbar