import React, { useEffect } from "react";
import Footer from "./Footer";

const TermsOfUSe = () => {
    useEffect(() => {
        window.scrollTo({
          behavior: "smooth",
          top: 0,
        });
      }, []);
  return (
    <>
      <div className="pt-24 p-10 mb-56">
        <p className="text-4xl font-medium">GPTZero.com Website <span className="underline font-bold">Terms & Conditions</span></p>
        <p className="mt-10">Version 1.0</p>
        <p className="mt-5 font-semibold text-black underline">
          If you use this website then you are accepting these terms and
          conditions.
        </p>
        <p className="mt-5 font-medium text-gray-500">
          {" "}
          Last Updated: September 11, 2023
        </p>
        <p className="mt-5 text-black font-semibold underline">Binding contract between you and GPTZero.com: </p>
        <p className="mt-5 font-medium text-gray-500">
          {" "}
          Thank you for visiting the GPTZero.com website. We request you read
          the terms and conditions carefully as your use of our website is an
          explicit acceptance thereof and is a binding contract between us. If
          you do not agree with all of the following terms and conditions,
          please do not use this site. We reserve the right, at our sole
          discretion, to change, modify or otherwise alter these terms and
          conditions at any time to which you are bound. Such changes and/or
          modifications shall become effective immediately upon the posting
          thereof. You means the user of the site. We or our means GPTZero.com
          and its employees and officers.
        </p>
        <p className="mt-5 text-black font-semibold">OWNERSHIP OF INTELLECTUAL PROPERTY: </p>
        <p className="mt-5 font-medium text-gray-500">
          {" "}
          All software, design, text, photographs, images, illustrations, audio,
          video, artwork, graphic material, or other copyrightable elements, and
          the selection and arrangements thereof, trademarks, service marks,
          trade names, trade dress, look and feel, patentable material and other
          Intellectual Property are owned by 2 microns ltd. With a primary
          address of B-02, Anand Vihar, Railway colony, Alwar, Rajasthan 301001
          India. and or its subsidiaries, affiliates or assigns, licensors or
          other owners. You agree not to disassemble, decompile, reverse
          engineer or otherwise modify our IP or our website. You agree to not
          use GPTZero.com trademarks, logos or slogans without written
          permission from an officer of GPTZero.com. You agree to not use any of
          our intellectual property without written permission from an officer
          of GPTZero.com. You agree that your use of the site and services are
          subject to all applicable local, state, national and international
          laws and regulations and you are solely responsible for all acts or
          omissions that occur under your account or password, including,
          without limitation, the contents of any transmissions through the
          Services for which you are responsible or which occur through the use
          of your password.
          </p>
          <p  className="mt-5 font-medium text-gray-500"> You agree to not use our Site for any purpose that
          is unlawful or prohibited by these terms, conditions, and notices, in
          any manner that could damage, disable, overburden, or impair our site.
          We may terminate your access to the GPTZero.com site at any time with
          or without cause. GPTZero.com shall not be liable to user or any other
          party for termination of the services or refusal to sell You goods and
          all terms found herein are non-negotiable. If any conflict exists
          between these terms & conditions and any other rules prorogated on our
          site, GPTZero.com shall have the right to decide which shall govern.
        </p>
        <p className="text-black mt-5 font-semibold">LIABILITY DISCLAIMER: </p>
        <p className="mt-5 font-medium text-gray-500">
          GPTZero.com makes no representations about the suitability,
          reliability, availability, timeliness, and accuracy of the
          information, products, services on the site for any purpose even if
          GPTZero.com knew, should have known or was grossly negligent. All
          products are provided "as is" without warranty of any kind.
          GPTZero.com hereby disclaims all warranties and conditions of
          merchantability, fitness for a particular purpose, workmanlike effort,
          title and non-infringement. Advice received via the GPTZero.com site
          should not be relied upon and you should consult an appropriate
          professional for specific advice tailored to your situation. In no
          event shall GPTZero.com be liable for any direct, indirect, punitive,
          incidental, special, consequential damages or any damages whatsoever.
          In the unlikely event damages are ascertained against us such
          liability shall be limited to liquidated damages of $500, an amount
          which you specifically acknowledge as a reasonable limit. GPTZero.com
          makes no warranties that the material on this site is legal or
          appropriate in any particular country. If you are dissatisfied with
          any of the GPTZero.com goods or services, or with any of these terms
          of use, your sole and exclusive remedy is to discontinue using the
          GPTZero.com site and or services. Your use of our web sites is at your
          own risk. Linking to the front page of the site may be done without
          permission so long as the link does not derive from an obscene,
          scandalous, profane, defamatory or unlawful site that, in
          GPTZero.com’s sole discretion, adversely affects the name or good will
          of GPTZero.com. Our website may contain links to other websites. We
          are not responsible for the content of these websites or for reviewing
          said sites.
        </p>
        <p className="mt-5 text-black font-semibold">Miscellaneous:</p>
        <p className="mt-5 font-medium text-gray-500">
          The article and section headings and the table of contents are for
          reference and convenience only and shall not be considered in the
          interpretation of this Agreement. Nothing in this agreement shall
          constitute the assignment of any patents, trademarks, copyrights,
          trade secrets or other intellectual property from us to you. If any
          provision of this Agreement is held by a court of competent
          jurisdiction to be contrary to law, then the remaining provisions of
          this Agreement will remain in full force and effect. No delay or
          omission by either party to exercise any right or power it has under
          this Agreement shall impair or be construed as a waiver of such right
          or power. A waiver by any party of any breach or covenant shall not be
          construed to be a waiver of any succeeding breach or any other
          covenant. All waivers must be in writing and signed by the party
          waiving its rights. This Agreement is the entire agreement between the
          parties with respect to its subject matter, and there are no other
          representations, understandings or agreements between the parties
          relative to such subject matter. This Agreement shall be governed by,
          and construed in accordance with, the laws of Rajasthan, India and all
          disputes shall be venued solely in Rajasthan, India. It shall be
          deemed that this click through contract has been signed in India. 
        </p>
        <p  className="mt-5 font-medium text-gray-500">Thank you very much for your time reading this binding contract and please have fun with our site.</p>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUSe;
