import "./App.css";
import {   HashRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";
import Navbar from "./Navbar";
import TermsOfUSe from "./TermsOfUse";

function App() {
  return (
   <>
   <HashRouter>
      <Navbar/>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/termsOfUse" element={<TermsOfUSe/>}/>
    </Routes>
   </HashRouter>
   </>
  );
}

export default App;
